// TODO: Right at the bottom of this file there is a even listener, not sure if that will work AS-IS
// This is a copy of the event listener that we have in bob where $rootScope is used to listen to events.
import {analyticeventMapping,analyticeutmMapping} from '../utils/common'
import {getCookie} from './cookieService'
import {get} from './localStorageService'

    var gtmSvc = {},
        pageName, pageData,
        sourceType = /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(navigator.userAgent) ? 'msite' : 'desktop';

    export const gtmClickButton = (INIT, id)=> {
        var gtmReplyData = {
            Ad_id: id,
            PAGE_TYPE: INIT,
            event: INIT
        };
        dataLayer.push(gtmReplyData);
    };

    export const gtmContactButton = (INIT, data)=> {
        var gtmReplyData = {
            PAGE_TYPE: INIT,
            event: INIT,
            email: data.emailId,
            mobile: data.phone
        };

        dataLayer.push(gtmReplyData);
    };

    export const gtmContactReq = (INIT, data)=> {
        var gtmReplyData = {
            PAGE_TYPE: INIT,
            event: INIT,
            email: data.emailId,
            mobile: data.phone
        };

        dataLayer.push(gtmReplyData);
    };

    export const gtmAdContactButton = (INIT, id, data)=> {
        var gtmReplyData = {
            Ad_id: id,
            PAGE_TYPE: INIT,
            event: INIT,
            email: data.emailId,
            mobile: data.phone
        };

        dataLayer.push(gtmReplyData);
    };

    export const gtmSnbfilter = (INIT, data)=> {
        var gtmReplyData = {
            PAGE_TYPE: INIT,
            event: INIT,
            Attributes: data
        };

        dataLayer.push(gtmReplyData);
    };

    export const gtmPapInnerPage = (attr, value)=> {
        var gtmReplyData = {
            PAGE_TYPE: 'PAP_ATTRIBUTE',
            event: 'PAP_ATTRIBUTE'
        };

        gtmReplyData[attr] = value;
        dataLayer.push(gtmReplyData);
    };

    export const gtmPapSuccess = (data, postAdid)=> {
        pageName = 'gtmPapSuccess';
        pageData = data;
        var pushValue = {
            "Ad-Type": data.ad_type,
            Ad_city_id: data.city_id,
            Ad_city_name: data.city_name,
            Ad_id: postAdid,
            Ad_image: "",
            Ad_locality: data.locality,
            Ad_title: data.title,
            "Area-Sq-Ft": data.area_in_sqft,
            Category_Name: "Real-Estate",
            Category_id: "20",
            Depth: "5",
            "No-of-Rooms": data.no_of_rooms,
            PAGE_TYPE: "PAS",
            event: "pap_submit_success",
            Price: data.price,
            SOURCE: sourceType,
            SubCategory_Name: data.sub_cat,
            SubCategory_id: "",
            USER_CITY_ID: getCookie("prefer_city_id"),
            USER_CITY_NAME: getCookie("new_prefer_city"),
            Property_type:data.property_type || '',
            User_type:data.user_type || '',
            "You-are": ""
        };
        dataLayer.unshift(pushValue);
    };

    export const gtmHome = ()=> {
        pageName = 'home';
        var pushValue = {
            Category_Name: 'Real-Estate',
            Category_id: '20',
            Depth: '1',
            PAGE_TYPE: "CATHOME",
            SOURCE: sourceType,
            USER_CITY_ID: getCookie("prefer_city_id"),
            USER_CITY_NAME: getCookie("new_prefer_city")
        };
        dataLayer.unshift(pushValue);
    };

    export const snbGtm = (gtmData, sub_cat)=> {
        pageName = 'snb';
        pageData = gtmData;
        var pushValue = {};
        var ad_type = gtmData.length > 0 ? gtmData[0].availableFor : '';
        var params = new URLSearchParams(document.location.search.substring(1));
        var searchKey = params.get("text");
        var pagetype,search_word;
        if(!searchKey){
            pagetype = 'BROWSE';
            search_word = '';
        }else{
            pagetype = 'SEARCH_RESULTS';
            search_word = searchKey;
        }

        pushValue = {
            Category_Name: 'Real-Estate',
            Category_id: '20',
            Depth: '3',
            PAGE_TYPE: pagetype,
            Search_Keyword: search_word,
            SOURCE: sourceType,
            SubCategory_Name: sub_cat,
            Ad_Type: ad_type,
            SubCategory_id: "",
            USER_CITY_ID: getCookie('prefer_city_id'),
            USER_CITY_NAME: getCookie('new_prefer_city')
        };

        dataLayer.unshift(pushValue);
    };


   export const vapGtm = (viewAdRespObj)=> {
        pageName = 'vap';
        pageData = viewAdRespObj;
        var vapData = viewAdRespObj.propertySnippet,
            localityData = viewAdRespObj.projectSnippet || {},
            projectData = viewAdRespObj.projectSnippet || {},
            builderData = viewAdRespObj.builderSnippet || {},
            furnished = vapData.furnishItems && vapData.furnishItems.length > 0 ? 'furnished' : 'Unfurnished',
            pushValue = {
                "Ad-Type": vapData.propertyFor,
                Ad_city_id: vapData.cityId,
                Ad_city_name: vapData.city,
                Ad_id: vapData.id,
                Ad_image: vapData.images,
                Ad_locality: vapData.locality[0],
                Ad_title: vapData.title,
                Area_Sq_Ft: (vapData.area + ' Sq. Ft.'),
                Category_Name: "Real-Estate",
                Category_id: "20",
                Depth: "4",
                Furnished: furnished,
                No_of_Rooms: (vapData && vapData.configuration ) ? (isNaN(vapData.configuration[2].count) ? vapData.configuration[2].count : (vapData.configuration[2].count + ' BHK')) : '',
                PAGE_TYPE: "VAP",
                Posted_On: vapData.createdTime,
                Price: vapData.price,
                SOURCE: sourceType,
                SubCategory_Name: vapData.categoryName,
                SubCategory_id: gtmSvc.getSubCategoryId(vapData.categoryName),
                USER_CITY_ID: getCookie("prefer_city_id"),
                USER_CITY_NAME: getCookie("new_prefer_city"),
                "You-are": vapData.userType,
                PROPERTY_TYPE: vapData.type,
                Project_id: projectData.id || "",
                Project_name: projectData.name || "",
                Locality_id: projectData.localityId || "",
                Locality_name: projectData && projectData.address && projectData.address.locality || "",
                Builder_id: builderData.id || "",
                Builder_name: builderData && builderData.builder &&builderData.builder.name || ""
            };
        dataLayer.unshift(pushValue);
    };

    export const projectGtm = function (data) {
        pageData = data;
        pageName = 'project';
        var projectData = data.projectSnippet,
            builderData = data.builderSnippet,
            pushValue = {
                Category_Name: "Real-Estate",
                Category_id: "20",
                PROJECT_NAME: data.projectSnippet.name,
                LOCALITY: data.projectSnippet.address.locality,
                SOURCE: sourceType,
                Depth: "3",
                PAGE_TYPE: "PROJECTHOME",
                USER_CITY_ID: getCookie("prefer_city_id"),
                USER_CITY_NAME: getCookie("new_prefer_city"),
                Project_id: projectData.id || "",
                Locality_id: projectData.localityId || "",
                Builder_id: builderData.id || "",
                Builder_name: builderData.builder.name || "",
                email: get("autofill_email") || "",
                mobile: get("autofill_no") || "",
                Attributes: '',
                PROPERTY_TYPE: data.projectSnippet.propertyTypes,
                PROJECT_CITY_ID: projectData.address.cityId,
                PROJECT_CITY_NAME: projectData.address.city
            };
        dataLayer.unshift(pushValue);
    };

    export const builderGtm = function (data) {
        pageName = 'builder';
        pageData = data;
        var pushValue = {
            Category_Name: "Real-Estate",
            Category_id: "20",
            Depth: "3",
            PAGE_TYPE: "BUILDERHOME",
            BUILDER_NAME: data.builder.name,
            SOURCE: sourceType,
            USER_CITY_ID: getCookie("prefer_city_id"),
            USER_CITY_NAME: getCookie("new_prefer_city"),
            Builder_id: data.id || "",
            email: get("autofill_email") || "",
            mobile: get("autofill_no") || "",
            Attributes: ''
        };
        dataLayer.unshift(pushValue);
    };

    export const localityGtm = function (data) {
        pageName = 'locality';
        var pushValue = {
            Category_Name: "Real-Estate",
            Category_id: "20",
            Depth: "3",
            PAGE_TYPE: "LOCALITYHOME",
            LOCALITY_NAME: data.name,
            SOURCE: sourceType,
            USER_CITY_ID: getCookie("prefer_city_id"),
            USER_CITY_NAME: getCookie("new_prefer_city"),
            Locality_id: data.id || "",
            email: get("autofill_email") || "",
            mobile: get("autofill_no") || "",
            Attributes: ''
        };
        dataLayer.unshift(pushValue);
    };

    export const individualGtm = function () {
        pageName = 'individual';
        var pushValue = {
            Category_Name: "Real-Estate",
            Category_id: "20",
            Depth: "3",
            PAGE_TYPE: "INDIVIDUALHOME",
            BUILDER_NAME: '',
            SOURCE: sourceType,
            SubCategory_Name: "Residential",
            USER_CITY_ID: getCookie("prefer_city_id"),
            USER_CITY_NAME: getCookie("new_prefer_city")
        };
        dataLayer.unshift(pushValue);
    };

    export const pap = function () {
        pageName = 'pap';
        var pushValue = {
            Category_Name: "Real-Estate",
            Category_id: "20",
            PAGE_TYPE: "PAP_MAIN",
            SOURCE: sourceType,
            SubCategory_Name: "Residential",
            USER_CITY_ID: getCookie("prefer_city_id"),
            USER_CITY_NAME: getCookie("new_prefer_city")
        };
        dataLayer.unshift(pushValue);
    };

    export const papRepeatedUser = function () {
        pageName = 'pap';
        var pushValue = {
            Category_Name: "Real-Estate",
            Category_id: "20",
            PAGE_TYPE: "PAP_MAIN",
            SOURCE: sourceType,
            SubCategory_Name: "Residential",
            USER_CITY_ID: getCookie("prefer_city_id"),
            USER_CITY_NAME: getCookie("new_prefer_city"),
            REPEATED_USER: "YES"
        };
        dataLayer.unshift(pushValue);
    };

    export const papSuccess = function () {
        pageName = 'pap';
        var pushValue = {
            Category_Name: "Real-Estate",
            Category_id: "20",
            PAGE_TYPE: "PAS",
            SOURCE: sourceType,
            SubCategory_Name: "Residential",
            USER_CITY_ID: getCookie("prefer_city_id"),
            USER_CITY_NAME: getCookie("new_prefer_city")
        };
        dataLayer.unshift(pushValue);
    };

    export const eap = function () {
        pageName = 'eap';
        var pushValue = {
            Category_Name: "Real-Estate",
            Category_id: "20",
            PAGE_TYPE: "EAP_MAIN",
            SOURCE: sourceType,
            SubCategory_Name: "Residential",
            USER_CITY_ID: getCookie("prefer_city_id"),
            USER_CITY_NAME: getCookie("new_prefer_city")
        };
        dataLayer.unshift(pushValue);
    };

    gtmSvc.getSubCategoryId = function (elem) {
        var obj = [{
            "catid": "18222211345",
            "translations": "Commercial Property for Rent",
            "cattitle": "Commercial-Property-for-Rent"
        }, {
            "catid": "261",
            "translations": "Commercial Property for Sale",
            "cattitle": "Commercial-Property-for-Sale"
        }, {
            "catid": "260",
            "translations": "Flatmates",
            "cattitle": "Flatmates"
        }, {
            "catid": "257",
            "translations": "Houses - Apartments for Rent",
            "cattitle": "Houses-Apartments-for-Rent"
        }, {
            "catid": "256",
            "translations": "Houses - Apartments for Sale",
            "cattitle": "Houses-Apartments-for-Sale"
        }, {
            "catid": "18222211427",
            "translations": "Land - Plot For Sale",
            "cattitle": "Land-Plot-For-Sale"
        }, {
            "catid": "258",
            "translations": "Paying Guest - Hostel",
            "cattitle": "Paying-Guest-Hostel"
        }, {
            "catid": "18245729",
            "translations": "Residential - Builder floors For Rent",
            "cattitle": "Residential-Builder-floors-For-Rent"
        }, {
            "catid": "18244799",
            "translations": "Residential - Builder floors For Sale",
            "cattitle": "Residential-Builder-floors-For-Sale"
        }, {
            "catid": "18222211386",
            "translations": "Service Apartments",
            "cattitle": "Service-Apartments"
        }, {
            "catid": "259",
            "translations": "Vacation Rentals - Timeshare",
            "cattitle": "Vacation-Rentals-Timeshare"
        }, {
            "catid": "18227736",
            "translations": "Villas/Bungalows for Rent",
            "cattitle": "Villas-Bungalows-for-Rent"
        }, {
            "catid": "18228659",
            "translations": "Villas/Bungalows for Sale",
            "cattitle": "Villas-Bungalows-for-Sale"
        }];
        var needle = elem;
        var subcatid = '';
        // iterate over each element in the array
        for (var i = 0; i < obj.length; i++) {
            // look for the entry with a matching `code` value
            if (obj[i].translations == needle) {
                subcatid = obj[i].catid;
            }
        }
        return subcatid;
    };

    //new gtm fun 
    export const eventTriggerQH = (gtmData)=> {
        gtmData['Category_id'] = 20;
        gtmData['Category_Name'] = "Real-Estate";
        gtmData['SOURCE'] = sourceType;
        gtmData['USER_CITY_ID'] = getCookie("prefer_city_id");
        gtmData['USER_CITY_NAME'] = getCookie("new_prefer_city");
        dataLayer.push(gtmData);
    }

    export const eventTriggerQproject = (gtmData)=> {
        gtmData['Category_id'] = 20;
        gtmData['Category_Name'] = "Real-Estate";
        gtmData['SOURCE'] = sourceType;
        gtmData['USER_CITY_ID'] = getCookie("prefer_city_id");
        gtmData['USER_CITY_NAME'] = getCookie("new_prefer_city");
        dataLayer.push(gtmData);
    }

    export const eventTriggeranalatyic = (gtmData)=> {
        var gtmEventAnalytic = analyticeventMapping(gtmData);
        var gtmUtmAnalytic = analyticeutmMapping(gtmData);
        var property = 'Quikr';
        let dataArr = ['_sendEventApiData', gtmEventAnalytic.event_name, sourceType, JSON.stringify(gtmEventAnalytic),JSON.stringify(gtmUtmAnalytic),property];
        _paqv3.push(dataArr);
    }
